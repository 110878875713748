import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize";

const UCalc = ({
	children,
	ucalcId,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		if (!document) return; // if (document.getElementById(`uCalc_${ucalcId}`)) return

		var script = document.createElement('script');
		script.id = `uCalc_${ucalcId}`;
		script.type = 'text/javascript';
		script.src = (document.location.protocol == "https:" ? "https:" : "http:") + `//ucalc.pro/api/widget.js?id=${ucalcId}&t=` + Math.floor(new Date() / 18e5);
		document.head.appendChild(script);
	}, [ucalcId]);
	return <div {...props} ref={ref}>
		    
		<div className={`uCalc_${ucalcId}`}></div>
		  
	</div>;
};

export default atomize(UCalc)({
	name: "UCalc",
	normalize: true,
	mixins: true,
	propInfo: {
		ucalcId: {
			title: {
				en: 'UCalc ID'
			},
			control: 'input'
		}
	}
});